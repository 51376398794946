import { ILocaleKeys } from '../locale-keys/LocaleKeys';

export enum GiftCardErrorCodes {
  GIFT_CARD_CODE_INVALID = 'GIFT_CARD_CODE_INVALID',
  GIFT_CARD_EXPIRED = 'GIFT_CARD_EXPIRED',
  GIFT_CARD_IS_EMPTY = 'GIFT_CARD_IS_EMPTY',
  GIFT_CARD_GENERAL_ERROR = 'GIFT_CARD_GENERAL_ERROR',
  GIFT_CARD_CURRENCY_INVALID = 'GIFT_CARD_CURRENCY_INVALID',
  GIFT_CARD_DISABLED = 'GIFT_CARD_DISABLED',
  GIFT_CARD_SERVICE_UNAVAILABLE = 'GIFT_CARD_SERVICE_UNAVAILABLE',
}

export const getErrorTextFromGiftCardError = (
  applyGiftCardError: string,
  localeKeys?: ILocaleKeys,
): string => {
  switch (applyGiftCardError) {
    case GiftCardErrorCodes.GIFT_CARD_EXPIRED:
      return (
        localeKeys?.checkoutSlot.paymentDetails.enterStoreCredit.error.expired() ||
        'checkoutSlot.paymentDetails.enterStoreCredit.error.expired'
      );
    case GiftCardErrorCodes.GIFT_CARD_DISABLED:
      return (
        localeKeys?.checkoutSlot.paymentDetails.enterStoreCredit.error.general() ||
        'checkoutSlot.paymentDetails.enterStoreCredit.error.general'
      );
    case GiftCardErrorCodes.GIFT_CARD_IS_EMPTY:
      return (
        localeKeys?.checkoutSlot.paymentDetails.enterStoreCredit.error.noBalance() ||
        'checkoutSlot.paymentDetails.enterStoreCredit.error.noBalance'
      );
    case GiftCardErrorCodes.GIFT_CARD_SERVICE_UNAVAILABLE:
      return (
        localeKeys?.checkoutSlot.paymentDetails.enterStoreCredit.error.serviceDown() ||
        'checkoutSlot.paymentDetails.enterStoreCredit.error.serviceDown'
      );
    case GiftCardErrorCodes.GIFT_CARD_CURRENCY_INVALID:
      return (
        localeKeys?.checkoutSlot.paymentDetails.enterStoreCredit.error.wrongCurrency() ||
        'checkoutSlot.paymentDetails.enterStoreCredit.error.wrongCurrency'
      );
    case GiftCardErrorCodes.GIFT_CARD_CODE_INVALID:
      return (
        localeKeys?.checkoutSlot.paymentDetails.enterStoreCredit.error.general() ||
        'checkoutSlot.paymentDetails.enterStoreCredit.error.general'
      );
    default:
      return (
        localeKeys?.checkoutSlot.paymentDetails.enterStoreCredit.error.general() ||
        'checkoutSlot.paymentDetails.enterStoreCredit.error.general'
      );
  }
};
