import { GetWalletDetailsResponse } from '@wix/ambassador-rise-integration-v1-wix-integration-provider/types';
import { getWalletDetails } from '@wix/ambassador-rise-integration-v1-wix-integration-provider/http';
import { Transactions } from '../monitoring/transactions';
import { ControllerFlowAPI } from '../types/types';

export class GetRiseWalletApi {
  private readonly flowAPI: ControllerFlowAPI;

  constructor({ flowAPI }: { flowAPI: ControllerFlowAPI }) {
    this.flowAPI = flowAPI;
  }

  async getWallet(currency: string): Promise<GetWalletDetailsResponse> {
    this.flowAPI.fedops.interactionStarted(Transactions.GetWallet);
    const response = await this.flowAPI.httpClient.request(
      getWalletDetails({ currency }),
    );
    this.flowAPI.fedops.interactionEnded(Transactions.GetWallet);

    return response.data;
  }
}
