import { updateCheckout } from '@wix/ambassador-ecom-v1-checkout/http';
import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { Transactions } from '../monitoring/transactions';
import { HttpError } from '@wix/http-client';
import {
  ApplicationStatus,
  ErrorData,
  ControllerFlowAPI,
} from '../types/types';
import { riseWixPluginErrorShownSrc35Evid202 } from '@wix/bi-logger-rise-ai-data/v2';
import {
  sitePluginId,
  sitePluginName,
} from '../components/RiseAppSitePlugin/consts';
import { getErrorTextFromGiftCardError } from '../utils/getErrorTextFromGiftCardError';

export interface UpdateCheckoutParams {
  checkout: Checkout;
  giftCardCode: string;
}

export class UpdateCheckoutApi {
  private readonly flowAPI: ControllerFlowAPI;

  constructor({ flowAPI }: { flowAPI: ControllerFlowAPI }) {
    this.flowAPI = flowAPI;
  }

  async updateCheckout({
    checkout,
    giftCardCode,
  }: UpdateCheckoutParams): Promise<Checkout> {
    this.flowAPI.fedops.interactionStarted(Transactions.ApplyStoreCredit);
    try {
      const response = await this.flowAPI.httpClient.request(
        updateCheckout({
          checkout,
          giftCardCode,
        }),
      );
      this.flowAPI.fedops.interactionEnded(Transactions.ApplyStoreCredit);

      return response.data.checkout!;
    } catch (e) {
      const error = e as HttpError;
      const giftCardError = error.response?.data as ErrorData;
      this.flowAPI.controllerConfig.setProps({
        status: ApplicationStatus.IDLE,
        giftCardError: giftCardError.details.applicationError?.code,
      });
      const errorKey = getErrorTextFromGiftCardError(
        giftCardError.details.applicationError?.code!,
      );
      this.flowAPI.bi?.report(
        riseWixPluginErrorShownSrc35Evid202({
          pluginId: sitePluginId,
          appName: sitePluginName,
          errorName: this.flowAPI.translations.t(errorKey),
        }),
      );

      return {};
    }
  }
}
