import model from './model';
import {
  riseWixPluginLoadedSrc35Evid200,
  riseWixPluginLoadingInitiatedSrc35Evid203,
} from '@wix/bi-logger-rise-ai-data/v2';
import { CheckoutService } from '../../services/CheckoutService';
import { WidgetBlocksIDs } from './consts';
import { SPECS, BalanceWidgetData, defaultBiMultiplier } from '../../consts';

export default model.createController(({ flowAPI, $w, controllerConfig }) => {
  const { wixCodeApi } = controllerConfig;
  const checkoutService = new CheckoutService({ flowAPI });

  const getFormattedAmount = (balance: string, currency: string) => {
    const formatCurrency = flowAPI.getCurrencyFormatter();
    return formatCurrency({ value: balance, currency });
  };

  const fetchStoreCreditBalance = async (currency: string) => {
    if (!currency) {
      flowAPI.reportError(new Error('Currency not found'));
      return { formattedBalance: null };
    }
    try {
      const { giftCardInfo } = await checkoutService.getRiseWallet(currency);
      if (!giftCardInfo || parseInt(giftCardInfo.balance!, 10) === 0) {
        return { formattedBalance: null };
      }

      return {
        formattedBalance: getFormattedAmount(
          giftCardInfo.balance!,
          giftCardInfo.currency!,
        ),
        balance: giftCardInfo.balance!,
      };
    } catch (error) {
      flowAPI.reportError(error as Error);
      return { formattedBalance: null };
    }
  };

  const getCreditWidgetLabel = (formattedBalance: string) => {
    return flowAPI.translations.t('creditWidget.memberCredit.label', {
      amount: formattedBalance,
    });
  };

  const updateWidgetContent = (label: string) => {
    $w(WidgetBlocksIDs.Content).label = label;
    $w(WidgetBlocksIDs.Container).expand();
  };

  const handleEditorOrPreviewMode = () => {
    const siteCurrency = wixCodeApi.site.currency || 'USD';
    const formattedBalance = getFormattedAmount('60.00', siteCurrency);
    const widgetContent = getCreditWidgetLabel(formattedBalance);

    updateWidgetContent(widgetContent);
  };

  const handleSiteMode = async () => {
    const currency = wixCodeApi.site.currency;
    const { formattedBalance, balance } = await fetchStoreCreditBalance(
      currency!,
    );

    if (formattedBalance) {
      const widgetContent = getCreditWidgetLabel(formattedBalance);
      updateWidgetContent(widgetContent);
      reportLoadedBiEvent(parseInt(balance, 10));
    }
  };

  const reportLoadingInitiatedBiEvent = () => {
    flowAPI.bi?.report(
      riseWixPluginLoadingInitiatedSrc35Evid203({
        pluginId: BalanceWidgetData.ID,
        appName: BalanceWidgetData.Name,
      }),
    );
  };

  const reportLoadedBiEvent = (balance: number) => {
    flowAPI.bi?.report(
      riseWixPluginLoadedSrc35Evid200({
        pluginId: BalanceWidgetData.ID,
        appName: BalanceWidgetData.Name,
        balance: balance! * defaultBiMultiplier,
      }),
    );
  };

  return {
    pageReady: async () => {
      const { experiments } = flowAPI;
      const { viewMode } = wixCodeApi.window;

      if (!experiments.enabled(SPECS.RiseAppBalanceWidget)) {
        return;
      }

      if (viewMode === 'Editor' || viewMode === 'Preview') {
        handleEditorOrPreviewMode();
      } else if (viewMode === 'Site') {
        reportLoadingInitiatedBiEvent();
        $w(WidgetBlocksIDs.Container).collapse();
        await handleSiteMode();
      }
    },
    exports: {},
  };
});
